import React from 'react';
import PropTypes from 'prop-types';
import PasswordResetNewPassword from '../../PasswordResetNewPassword/PasswordResetNewPassword.js';

export default function PasswordResetNewPasswordPage(
	user_password_update_endpoint
) {
	if (!user_password_update_endpoint) {
		return <div>Error: Missing password update endpoint</div>;
	}
	
	return (
		<div className="container p-4 pt-4 lg:pt-14 md:px-20">
			<PasswordResetNewPassword
				user_password_update_endpoint={user_password_update_endpoint}
			/>
		</div>
	);
}

PasswordResetNewPasswordPage.propTypes = {
	user_password_update_endpoint: PropTypes.string.isRequired
};
